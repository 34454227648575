import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Select from "react-select";

const ContactSelect = ({ selectedContactIds, onChange, isMulti = true, disabled = false }) => {
	const [optionList, setOptionList] = useState([]);
	const [selections, setSelections] = useState(isMulti ? [] : null);

	useEffect(() => {
		(async () => {
			try {
				const {data} = await api.get("/contacts", {params: {all: true, ignoreGroup: true}});

				setOptionList(data.contacts?.map(contact => {
					return {value: contact.id, label: contact.name};
				}));
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	useEffect(() => {
		let ids = [];
		if (isMulti && selectedContactIds) {
			selectedContactIds.map(contactId => {
				let result = optionList.filter(obj => {
					return obj.value === contactId;
				});
				return ids.push(result[0])
			});

			setSelections(ids);
		}

		if (!isMulti && selectedContactIds) {
			setSelections(selectedContactIds)
		}
	}, [optionList, selectedContactIds, isMulti]);

	const handleChange = e => {
		setSelections(e);
		const ids = isMulti ? e?.map(selection => selection.value) : e;
		onChange(ids);
	};

	return (
		<div>
			<FormControl fullWidth margin="dense" variant="outlined">
				<Select
					options={optionList}
					placeholder="Selecione um contato"
					value={selections}
					onChange={handleChange}
					isSearchable={true}
					isMulti={isMulti ?? undefined}
					menuPosition="fixed"
					isDisabled={disabled}
				/>
			</FormControl>
		</div>
	)
}

export default ContactSelect;