import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import openSocket from "socket.io-client";

import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import axios from "axios";

const useAuth = () => {
	const history = useHistory();
	const [isAuth, setIsAuth] = useState(false);
	const [isBlocked, setIsBlocked] = useState(false);
	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState({});

	api.interceptors.request.use(
		config => {
			const token = localStorage.getItem("token");
			if (token) {
				config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
				setIsAuth(true);
			}
			return config;
		},
		error => {
			Promise.reject(error);
		}
	);

	api.interceptors.response.use(
		response => {
			return response;
		},
		async error => {
			const originalRequest = error.config;
			if (error?.response?.status === 403 && !originalRequest._retry) {
				originalRequest._retry = true;

				const { data } = await api.post("/auth/refresh_token");
				if (data) {
					localStorage.setItem("token", JSON.stringify(data.token));
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
				}
				return api(originalRequest);
			}
			if (error?.response?.status === 401) {
				localStorage.removeItem("token");
				api.defaults.headers.Authorization = undefined;
				setIsAuth(false);
			}
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		const token = localStorage.getItem("token");
		(async () => {
			if (token) {
				try {
					const { data } = await api.post("/auth/refresh_token");
					api.defaults.headers.Authorization = `Bearer ${data.token}`;
					setIsAuth(true);
					setUser(data.user);
				} catch (err) {
					toastError(err);
				}
			}
			setLoading(false);
		})();
	}, []);

	useEffect( () => {
		const cnpj = process.env.REACT_APP_CNPJ;
		const adminUrl = process.env.REACT_APP_ADMIN_URL;
		(async () => {
			if (adminUrl && cnpj) {
				const {data} = await axios.get(adminUrl + "/clients/find/" + cnpj);
				console.log(data);
				if (data) {
					if (data.status !== "OK")
						setIsBlocked(true);
				}
			}
		})();
	}, []);

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("user", data => {
			if (data.action === "update" && data.user.id === user.id) {
				setUser(data.user);
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user]);

	const handleLogin = async userData => {
		setLoading(true);

		try {
			const { data } = await api.post("/auth/login", userData);
			localStorage.setItem("token", JSON.stringify(data.token));
			api.defaults.headers.Authorization = `Bearer ${data.token}`;
			setUser(data.user);
			setIsAuth(true);

			if (('serviceWorker' in navigator) && ('PushManager' in window) && ('Notification' in window)) {
				if (Notification.permission === "granted") {
					navigator.serviceWorker.register("./worker.js").then(async serviceWorker => {
						let subscription = await serviceWorker.pushManager.getSubscription();

						if (!subscription) {
							const {data: publicKeyResponse} = await api.get("/push/public_key");

							subscription = await serviceWorker.pushManager.subscribe({
								applicationServerKey: publicKeyResponse.publicKey,
								userVisibleOnly: true
							});
						}
						console.log(subscription);

						await api.post("/push/register", {
							subscription: JSON.stringify(subscription),
							userId: data.user.id
						});

						/*await api.post("/push/send", {
                          subscription
                        });*/
					}).catch(err => {
						console.log(err)
					});
				}
			}

			if (!('serviceWorker' in navigator))
				console.log("Service Worker isn't supported on this browser, disable or hide UI.");

			if (!('PushManager' in window))
				console.log("Push isn't supported on this browser, disable or hide UI.");

			toast.success(i18n.t("auth.toasts.success"));
			history.push("/tickets");
			setLoading(false);
		} catch (err) {
			const errorMsg = err.response?.data?.message || err.response.data.error;
			if (errorMsg === "ERR_INVALID_WORKING_TIME") {
				toast.info("Você está fora do seu horário de trabalho. Seu acesso está temporariamente bloqueado.");
				setLoading(false);
				return;
			}
			toastError(err);
			setLoading(false);
		}
	};

	const handleLogout = async () => {
		setLoading(true);

		try {
			await api.delete("/auth/logout");
			setIsAuth(false);
			setUser({});
			localStorage.removeItem("token");
			api.defaults.headers.Authorization = undefined;

			if (('serviceWorker' in navigator) && ('PushManager' in window)) {
				navigator.serviceWorker.getRegistrations().then(async function (registrations) {
					for (let registration of registrations) {
						let subscription = await registration.pushManager.getSubscription();

						if (subscription) {
							let subscriptionJSON = await subscription.toJSON();
							let key = (subscriptionJSON.keys.p256dh);

							await api.post("/push/unregister", {
								userId: user.id,
								key
							});

							await registration.unregister();
						}
					}
				});
			}

			setLoading(false);
			history.push("/login");
		} catch (err) {
			toastError(err);
			setLoading(false);
		}
	};

	return { isAuth, isBlocked, user, loading, handleLogin, handleLogout };
};

export default useAuth;
