import React, {useContext, useEffect, useReducer, useState} from "react";

import openSocket from "socket.io-client";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
// import SearchIcon from '@material-ui/icons/Search';
import Edit from '@material-ui/icons/Edit';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import {format, parseISO} from "date-fns";
import AgendamentoInfoModal from "../../components/AgendamentoInfoModal";
import AgendamentoModal from "../../components/AgendamentoModal";
import AgendamentoLogModal from "../../components/AgendamentoLogModal";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_AGENDAMENTOS") {
    const agendamentos = action.payload;
    const newAgendamentos = [];

    agendamentos.forEach((agendamento) => {
      const agendamentoIndex = state.findIndex((q) => q.id === agendamento.id);
      if (agendamentoIndex !== -1) {
        state[agendamentoIndex] = agendamento;
      } else {
        newAgendamentos.push(agendamento);
      }
    });

    return [...state, ...newAgendamentos];
  }

  if (action.type === "UPDATE_AGENDAMENTOS") {
    const agendamento = action.payload;
    const agendamentoIndex = state.findIndex((u) => u.id === agendamento.id);

    if (agendamentoIndex !== -1) {
      state[agendamentoIndex] = agendamento;
      return [...state];
    } else {
      return [agendamento, ...state];
    }
  }

  if (action.type === "DELETE_AGENDAMENTO") {
    const agendamentoId = action.payload;
    const agendamentoIndex = state.findIndex((q) => q.id === agendamentoId);
    if (agendamentoIndex !== -1) {
      state.splice(agendamentoIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const Agendamento = () => {
  const classes = useStyles();

  const [agendamentos, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const weekdays = { 'Monday': 'Segunda-Feira', 'Tuesday': 'Terça-Feira', 'Wednesday': 'Quarta-Feira', 'Thursday': 'Quinta-Feira', 'Friday': 'Sexta-Feira', 'Saturday': 'Sábado', 'Sunday': 'Domingo'};

  const [agendamentoModalOpen, setAgendamentoModalOpen] = useState(false);
  const [agendamentoModalInfoOpen, setAgendamentoModalInfoOpen] = useState(false);
  const [agendamentoModalLogOpen, setAgendamentoModalLogOpen] = useState(false);
  const [selectedAgendamento, setSelectedAgendamento] = useState(null);
  const [selectedAgendamentoLog, setSelectedAgendamentoLog] = useState(null);
  const [selectedAgendamentoDelete, setSelectedAgendamentoDelete] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/agendamento");
        dispatch({ type: "LOAD_AGENDAMENTOS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("agendamento", (data) => {
      if (data.action === "update" || data.action === "create") {
        toast.success('Agendamento atualizado!');
        dispatch({ type: "UPDATE_AGENDAMENTOS", payload: data.agendamento });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_AGENDAMENTO", payload: data.agendamentoId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenAgendamentoModal = () => {
    setAgendamentoModalOpen(true);
    setSelectedAgendamento(null);
  };

  const handleCloseAgendamentoModal = () => {
    setAgendamentoModalOpen(false);
    setSelectedAgendamento(null);
  };

  const handleCloseAgendamentoInfoModal = () => {
    setAgendamentoModalInfoOpen(false);
    setSelectedAgendamento(null);
  };

  const handleCloseAgendamentoLogModal = () => {
    setAgendamentoModalLogOpen(false);
    setSelectedAgendamentoLog(null);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedAgendamento(null);
  };

  const handleCloseConfirmationDeleteModal = () => {
    setConfirmDeleteModalOpen(false);
    setSelectedAgendamento(null);
  };

  const handleDeleteAgendamento = async () => {
    try {
      await api.delete(`/agendamento/${selectedAgendamentoDelete?.id}`);

      await api.post("/log", {
        log: {
          descricao: `O usuário ${user?.name} excluiu o agendamento ${selectedAgendamentoDelete?.id}`,
          origem: 'Agendamento',
          userId: user?.id,
          agendamentoId: selectedAgendamentoDelete?.id
        }
      });
      toast.success("Agendamento excluido com sucesso!");
    } catch (err) {
      toastError(err);
    }

    setConfirmDeleteModalOpen(false);
    setSelectedAgendamentoDelete(null);
  };

  /*const handleDeleteEnvio = async (queueId) => {
    try {
      await api.delete(`/envio/${queueId}`);
      toast.success(i18n.t("Envio deleted successfully!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedEnvio(null);
  };*/

  return (
    <MainContainer>
      <ConfirmationModal
        title={"Atenção!"}
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleOpenAgendamentoModal}
      >
        Por medidas de segurança, e para atender as normas da Meta/Facebook, as mensagens em agendadas serão enviadas com intervalos de 15 segundos!
      </ConfirmationModal>

      <ConfirmationModal
        title={"Atenção!"}
        open={confirmDeleteModalOpen}
        onClose={handleCloseConfirmationDeleteModal}
        onConfirm={handleDeleteAgendamento}
      >
        Deseja realmente excluir esse agendamento ({selectedAgendamentoDelete?.id })? Se o envio estiver sendo efetuado ele NÃO será cancelado!
      </ConfirmationModal>
      <AgendamentoModal
        open={agendamentoModalOpen}
        onClose={handleCloseAgendamentoModal}
        agendamentoId={selectedAgendamento?.id}
      />
      <AgendamentoInfoModal
          open={agendamentoModalInfoOpen}
          onClose={handleCloseAgendamentoInfoModal}
          agendamentoId={selectedAgendamento?.id}
      />
      <AgendamentoLogModal
          open={agendamentoModalLogOpen}
          onClose={handleCloseAgendamentoLogModal}
          agendamentoId={selectedAgendamentoLog?.id}
      />
      <MainHeader>
        <Title>Agendamentos</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              setConfirmModalOpen(true);
            }}
          >
            Novo agendamento
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Mensagem
              </TableCell>
              <TableCell align="center">
                Tipo de recorrência
              </TableCell>
              <TableCell align="center">
                Recorrência
              </TableCell>
              <TableCell align="center">
                Horário de envio
              </TableCell>
              <TableCell align="center">
                Dia de envio
              </TableCell>
              <TableCell align="center">
                Enviados
              </TableCell>
              <TableCell align="center">
                Total
              </TableCell>
              <TableCell align="center">
                Destinatários
              </TableCell>
              <TableCell align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {agendamentos.map((agendamento) => (
                <TableRow key={agendamento.id}>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {agendamento.message}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell align="center">{
                    agendamento.recurrence === "once" ? "Único" :
                      agendamento.recurrence === "every_day" ? "Todo dia" :
                        agendamento.recurrence === "every_month" ? "Todo mês" :
                          agendamento.recurrence === "every_week" ? "Semanal" :
                          agendamento.recurrence === "quarterly" ? "Trimestral" :
                            agendamento.recurrence }</TableCell>

                  <TableCell align="center">{
                    agendamento.recurrence === "once" && agendamento.RValue === "1" ? agendamento.total !== agendamento.enviados ? "Enviando" : "Já enviado" :
                    agendamento.recurrence === "once" && agendamento.RValue === "0" ? "Aguardando envio" :
                      agendamento.recurrence === "last_day_of_month" && agendamento.RValue === "1" ? "Já eviado" :
                        agendamento.recurrence === "business_day" ? agendamento.RValue + "th" :
                          agendamento.recurrence === "every_month" ? "Dia " + agendamento.RValue :
                            agendamento.recurrence === "every_week" ? weekdays[agendamento.RValue] :
                            agendamento.recurrence === "quarterly" ? `Meses: ${parseInt(agendamento.RValue.split("/")[1])} | ${(parseInt(agendamento.RValue.split("/")[1]) + 3) > 12 ? parseInt(agendamento.RValue.split("/")[1]) + 3 -12 : (parseInt(agendamento.RValue.split("/")[1]) + 3)} | ${(parseInt(agendamento.RValue.split("/")[1]) + 6) > 12 ? parseInt(agendamento.RValue.split("/")[1]) + 6 -12 : (parseInt(agendamento.RValue.split("/")[1]) + 6)} | ${(parseInt(agendamento.RValue.split("/")[1]) + 9) > 12 ? parseInt(agendamento.RValue.split("/")[1]) + 9 -12 : (parseInt(agendamento.RValue.split("/")[1]) + 9)}` :
                              agendamento.recurrence === "every_day" ? format(parseISO(agendamento.sendAt), "HH:mm") :
                              agendamento.RValue }</TableCell>

                  <TableCell align="center">{format(parseISO(agendamento.sendAt), "HH:mm")}</TableCell>

                  <TableCell align="center">{
                    agendamento.recurrence === "once" ? format(parseISO(agendamento.sendAt), "dd/MM/yyyy") :
                        agendamento.recurrence === "every_day" ? format(new Date(), "dd/MM/yyyy") :
                            agendamento.recurrence === "every_month" ? format(new Date().setDate(agendamento.RValue), "dd/MM/yyyy") :
                            agendamento.recurrence === "quarterly" ? `Dia ${agendamento.RValue.split("/")[0]}` :
                                agendamento.recurrence === "every_week" ? weekdays[agendamento.RValue] : null
                  }</TableCell>

                  <TableCell align="center">{agendamento.enviados}</TableCell>

                  <TableCell align="center">{agendamento.total !== 0 ? agendamento.total : 'Aguardando envio...' }</TableCell>

                  <TableCell align="center">{agendamento.allContacts ? "Todos os contatos" : "Tag/Contato"}</TableCell>

                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedAgendamento(agendamento);
                        setAgendamentoModalOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>

                    {/*<IconButton
                      size="small"
                      onClick={() => {
                        setSelectedAgendamento(agendamento);
                        setAgendamentoModalInfoOpen(true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>*/}

                    <IconButton
                        size="small"
                        onClick={() => {
                          setSelectedAgendamentoLog(agendamento);
                          setAgendamentoModalLogOpen(true);
                        }}
                    >
                      <PersonOutlineOutlinedIcon />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedAgendamentoDelete(agendamento);
                        setConfirmDeleteModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Agendamento;
