import React, {useContext, useEffect, useReducer, useState} from "react";

import openSocket from "socket.io-client";

import {
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { Close, Done, VisibilityOutlined } from "@material-ui/icons";
// import TagModal from "../../components/TagModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import {format, parseISO} from "date-fns";
import {AuthContext} from "../../context/Auth/AuthContext";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_TICKETS_BOT") {
    const ticketsBot = action.payload;
    const newTicketsBot = [];

    ticketsBot.forEach((ticketBot) => {
      const ticketBotIndex = state.findIndex((t) => t.id === ticketBot.id);
      if (ticketBotIndex !== -1) {
        state[ticketBotIndex] = ticketBot;
      } else {
        newTicketsBot.push(ticketBot);
      }
    });

    return [...state, ...newTicketsBot];
  }

  if (action.type === "UPDATE_TICKETS_BOT") {
    const ticketBot = action.payload;
    const ticketBotIndex = state.findIndex((u) => u.id === ticketBot.id);

    if (ticketBotIndex !== -1) {
      state[ticketBotIndex] = ticketBot;
      return [...state];
    } else {
      return [ticketBot, ...state];
    }
  }

  if (action.type === "DELETE_TICKET_BOT") {
    const ticketBotId = action.payload;
    const ticketBotIndex = state.findIndex((t) => t.id === ticketBotId);
    if (ticketBotIndex !== -1) {
      state.splice(ticketBotIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const TicketsBot = () => {
  const classes = useStyles();

  const [ticketsBot, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  // const [ticketBotModalOpen, setTicketBotOpen] = useState(false);
  const [selectedTicketBot, setSelectedTicketBot] = useState(null);
  const [selectedTicketBot2, setSelectedTicketBot2] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmModalOpen2, setConfirmModalOpen2] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/ticket2");
        dispatch({ type: "LOAD_TICKETS_BOT", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("ticketBot", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_TICKETS_BOT", payload: data.ticket });
      }

      if (data.action === "reload") {
        dispatch({ type: "DELETE_TICKET_BOT", payload: data.ticketId });
      }

      /*if (data.action === "delete") {
        dispatch({ type: "DELETE_TICKET_BOT", payload: data.ticketBotId });
      }*/
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  /*const handleOpenTicketBotModal = () => {
    setTicketBotOpen(true);
    setSelectedTicketBot(null);
  };*/

  /*const handleCloseTicketBotModal = () => {
    setTicketBotOpen(false);
    setSelectedTicketBot(null);
  };

  const handleEditTicketBot = (queue) => {
    setSelectedTicketBot(queue);
    setTicketBotOpen(true);
  };*/

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedTicketBot(null);
  };

  const handleCloseConfirmationModal2 = () => {
    setConfirmModalOpen2(false);
    setSelectedTicketBot2(null);
  };

  const handleUpdateTicketBot = async (ticketBotId, status) => {
    try {
      await api.put(`/ticket2/${ticketBotId}`, {ticket:ticketBotId, status, user});
      toast.success(i18n.t("Ticket atualizado com sucesso!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedTicketBot(null);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedTicketBot &&
          `Atender ticket ${
              selectedTicketBot.id
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleUpdateTicketBot(selectedTicketBot.id, 'pending')}
      >
        Esta ação irá colocar o esse ticket na aba de pendentes, deseja continuar?
      </ConfirmationModal>

      <ConfirmationModal
          title={
              selectedTicketBot2 &&
              `Fechar ticket ${
                  selectedTicketBot2.id
              }?`
          }
          open={confirmModalOpen2}
          onClose={handleCloseConfirmationModal2}
          onConfirm={() => handleUpdateTicketBot(selectedTicketBot2.id, 'closed')}
      >
        Você tem certeza? Essa ação irá encerrar o ticket e colola-lo na aba de encerrados.
      </ConfirmationModal>
      {/*<TagModal
        open={ticketBotModalOpen}
        onClose={handleCloseTicketBotModal}
        tagId={selectedTicketBot?.id}
      />*/}
      <MainHeader>
        <Title>Atendidos pelo BOT</Title>
        <MainHeaderButtonsWrapper>
          {/*<Button
            variant="contained"
            color="primary"
            onClick={handleOpenTicketBotModal}
          >
            Adicionar Ticket Bot
          </Button>*/}
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Identificação
              </TableCell>
              <TableCell align="center">
                Contato
              </TableCell>
              <TableCell align="center">
                Última mensagem
              </TableCell>
              <TableCell align="center">
                Data
              </TableCell>
              <TableCell align="center">
                Fila
              </TableCell>
              <TableCell align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {ticketsBot.map((ticketBot) => (
                <TableRow key={ticketBot.id}>
                  <TableCell align="center">{ticketBot.id}</TableCell>
                  <TableCell align="center">
                    <Avatar src={ticketBot.contact.profilePicUrl} style={{display: "inline-block"}} />
                    <Typography variant="subtitle2" style={{display: "inline-block", verticalAlign: "top", marginTop: 10, marginLeft: 8}}>{ticketBot.contact.name}</Typography>
                  </TableCell>
                  <TableCell align="center">{ticketBot.lastMessage}</TableCell>
                  <TableCell align="center">{format(parseISO(ticketBot.updatedAt), "dd/MM/yy HH:mm")}</TableCell>
                  <TableCell align="center">
                    <Chip variant="outlined" label={ticketBot.queue?.name} clickable={false} style={{color: ticketBot.queue?.color, borderColor: ticketBot.queue?.color}} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedTicketBot(ticketBot);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <Done /> Atender
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedTicketBot2(ticketBot);
                        setConfirmModalOpen2(true);
                      }}
                    >
                      <Close /> Fechar
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        window.open("tickets/" + ticketBot?.id);
                      }}
                    >
                      <VisibilityOutlined /> Visualizar
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default TicketsBot;
