import React, {useState, useEffect, useReducer, useContext} from "react";
import openSocket from "socket.io-client";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import {format, parseISO} from "date-fns";
import ProtocoloModal from "../../components/ProtocoloModal";
import {AuthContext} from "../../context/Auth/AuthContext";

const reducer = (state, action) => {
  if (action.type === "LOAD_PROTOCOLOS") {
    const protocolos = action.payload;
    const newProtocolos = [];

    protocolos.forEach((protocolo) => {
      const protocoloIndex = state.findIndex((c) => c.id === protocolo.id);
      if (protocoloIndex !== -1) {
        state[protocoloIndex] = protocolo;
      } else {
        newProtocolos.push(protocolo);
      }
    });

    return [...state, ...newProtocolos];
  }

  if (action.type === "UPDATE_PROTOCOLOS") {
    const protocolo = action.payload;
    const protocoloIndex = state.findIndex((c) => c.id === protocolo.id);

    if (protocoloIndex !== -1) {
      state[protocoloIndex] = protocolo;
      return [...state];
    } else {
      return [protocolo, ...state];
    }
  }

  if (action.type === "DELETE_PROTOCOLO") {
    const protocoloId = action.payload;

    const protocoloIndex = state.findIndex((c) => c.id === protocoloId);
    if (protocoloIndex !== -1) {
      state.splice(protocoloIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Protocolos = () => {
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [protocolos, dispatch] = useReducer(reducer, []);
  const [selectedProtocoloId, setSelectedProtocoloId] = useState(null);
  const [protocoloModalOpen, setProtocoloModalOpen] = useState(false);
  const [deletingContact, setDeletingContact] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchProtocolos = async () => {
        try {
          const { data } = await api.get("/protocolos/", {
            params: { searchParam, pageNumber, user },
          });
          dispatch({ type: "LOAD_PROTOCOLOS", payload: data.protocolos });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (err) {
          toastError(err);
        }
      };
      fetchProtocolos();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchParam, pageNumber, user]);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("protocolo", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PROTOCOLOS", payload: data.protocolo });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_PROTOCOLO", payload: +data.protocoloId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleCloseContactModal = () => {
    setSelectedProtocoloId(null);
    setProtocoloModalOpen(false);
  };

  const hadleEditProtocolo = (contactId) => {
    setSelectedProtocoloId(contactId);
    setProtocoloModalOpen(true);
  };

  const handleDeleteContact = async (contactId) => {
    try {
      await api.delete(`/contacts/${contactId}`);
      toast.success(i18n.t("contacts.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeletingContact(null);
    setSearchParam("");
    setPageNumber(1);
  };

  const handleimportContact = async () => {
    try {
      await api.post("/contacts/import");
      history.go(0);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenTicket = async (ticketId) => {
    if (!ticketId) return;
    setLoading(true);
    try {
      history.push(`/tickets/${ticketId}`);
    } catch (err) {
      toastError(err);
    }
    setLoading(false);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer className={classes.mainContainer}>
      <ProtocoloModal
        open={protocoloModalOpen}
        onClose={handleCloseContactModal}
        aria-labelledby="form-dialog-title"
        protocoloId={selectedProtocoloId}
      ></ProtocoloModal>
      <ConfirmationModal
        title={
          deletingContact
            ? `${i18n.t("contacts.confirmationModal.deleteTitle")} ${
                deletingContact.name
              }?`
            : `${i18n.t("contacts.confirmationModal.importTitlte")}`
        }
        open={confirmOpen}
        onClose={setConfirmOpen}
        onConfirm={(e) =>
          deletingContact
            ? handleDeleteContact(deletingContact.id)
            : handleimportContact()
        }
      >
        {deletingContact
          ? `${i18n.t("contacts.confirmationModal.deleteMessage")}`
          : `${i18n.t("contacts.confirmationModal.importMessage")}`}
      </ConfirmationModal>
      <MainHeader>
        <Title>{"Protocolos"}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          {/*<Button
            variant="contained"
            color="primary"
            onClick={handleOpenContactModal}
          >
            {i18n.t("contacts.buttons.add")}
          </Button>*/}
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell>{i18n.t("contacts.table.name")}</TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                Protocolo
              </TableCell>
              <TableCell align="center">
                Ticket ID
              </TableCell>
              <TableCell align="center">
                Data
              </TableCell>
              <TableCell align="center">
                {i18n.t("contacts.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {protocolos.map((protocolo) => (
                <TableRow key={protocolo.id}>
                  <TableCell style={{ paddingRight: 0 }}>
                    {<Avatar src={protocolo.contact?.profilePicUrl} />}
                  </TableCell>
                  <TableCell>{protocolo.contact?.name}</TableCell>
                  <TableCell align="center">{protocolo.contact?.number}</TableCell>
                  <TableCell align="center">{protocolo.protocolo}</TableCell>
                  <TableCell align="center">{protocolo.ticketId}</TableCell>
                  <TableCell align="center">{format(parseISO(protocolo.createdAt), "dd/MM/yy HH:mm")}</TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleOpenTicket(protocolo.ticketId)}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => hadleEditProtocolo(protocolo.id)}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton avatar columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Protocolos;
