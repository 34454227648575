import React, { useEffect, useReducer, useState } from "react";

import openSocket from "socket.io-client";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
// import QueueModal from "../../components/QueueModal";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import PerguntaModal from "../../components/PerguntaModal";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_PERGUNTAS") {
    const perguntas = action.payload;
    const newPerguntas = [];

    perguntas.forEach((pergunta) => {
      const perguntaIndex = state.findIndex((q) => q.id === pergunta.id);
      if (perguntaIndex !== -1) {
        state[perguntaIndex] = pergunta;
      } else {
        newPerguntas.push(pergunta);
      }
    });

    return [...state, ...newPerguntas];
  }

  if (action.type === "UPDATE_PERGUNTAS") {
    const pergunta = action.payload;
    const perguntaIndex = state.findIndex((u) => u.id === pergunta.id);

    if (perguntaIndex !== -1) {
      state[perguntaIndex] = pergunta;
      return [...state];
    } else {
      return [pergunta, ...state];
    }
  }

  if (action.type === "DELETE_PERGUNTA") {
    const perguntaId = action.payload;
    const perguntaIndex = state.findIndex((q) => q.id === perguntaId);
    if (perguntaIndex !== -1) {
      state.splice(perguntaIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const PerguntasRespostas = () => {
  const classes = useStyles();

  const [perguntas, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [perguntaModalOpen, setPerguntaModalOpen] = useState(false);
  const [selectedPergunta, setSelectedPergunta] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/pergunta");
        dispatch({ type: "LOAD_PERGUNTAS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

    socket.on("pergunta", (data) => {
      if (data.action === "update" || data.action === "create") {
        dispatch({ type: "UPDATE_PERGUNTAS", payload: data.perguntas });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_PERGUNTA", payload: data.perguntaId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenPerguntaModal = () => {
    setPerguntaModalOpen(true);
    setSelectedPergunta(null);
  };

  const handleClosePerguntaModal = () => {
    setPerguntaModalOpen(false);
    setSelectedPergunta(null);
  };

  const handleEditPergunta = (pergunta) => {
    setSelectedPergunta(pergunta);
    setPerguntaModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedPergunta(null);
  };

  const handleDeletePergunta = async (queueId) => {
    try {
      await api.delete(`/pergunta/${queueId}`);
      toast.success(i18n.t("Pergunta deleted successfully!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedPergunta(null);
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          selectedPergunta &&
          `Excluir ${
            selectedPergunta.pergunta
          }?`
        }
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeletePergunta(selectedPergunta.id)}
      >
        Você tem certeza? Essa ação não pode ser revertida!
      </ConfirmationModal>
      <PerguntaModal
        open={perguntaModalOpen}
        onClose={handleClosePerguntaModal}
        perguntaId={selectedPergunta?.id}
      />
      <MainHeader>
        <Title>Perguntas e Respostas</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenPerguntaModal}
          >
            Adicionar
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Pergunta
              </TableCell>
              <TableCell align="center">
                Resposta
              </TableCell>
              <TableCell align="center">
                Fila
              </TableCell>
              <TableCell align="center">
                Abrir ticket
              </TableCell>
              <TableCell align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {perguntas.map((pergunta) => (
                <TableRow key={pergunta.id}>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {pergunta.pergunta}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {pergunta.resposta}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell align="center">{pergunta.queue?.name}</TableCell>

                  <TableCell align="center">{pergunta.openTicket ? "Sim" : "Não"}</TableCell>

                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEditPergunta(pergunta)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedPergunta(pergunta);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default PerguntasRespostas;
